import React from "react"

import { Layout } from "../components/Layout"
import Seo from "../components/Seo"

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Страница не найдена" />
    <h1>404: Страница не найдена</h1>
  </Layout>
)

export default NotFoundPage
